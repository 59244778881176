import INK_COLOR from './inkColor';
import cropSignatureCanvas from './cropSignatureCanvas';

// -------------- --------------------------------

export const MIN_FONT_SIZE = 12;
const DEFAULT_CANVAS_HEIGHT = 36;

const writeNameOnTheCanvas = (name: string, signatureHeight: number) => {
  const canvasHeight = Math.max(signatureHeight, DEFAULT_CANVAS_HEIGHT);
  const canvas = document.createElement('canvas');
  canvas.width = 9999;
  canvas.height = canvasHeight;

const calculatedFontSize = Math.round(signatureHeight / 3);

const fontSize = Math.max(calculatedFontSize, MIN_FONT_SIZE);

  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = `italic ${fontSize}px serif`;
    ctx.fillStyle = INK_COLOR;
    ctx.fillText(name, 10, fontSize + 10);

    return cropSignatureCanvas(canvas);
  }

  return null;
};

export default writeNameOnTheCanvas;
